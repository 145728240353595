import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = () => ({
  root: {
    maxWidth: '1200px',
    display: 'flex',
    allign: 'center',

  },
  outputText: {
    marginLeft: '8px',
    allign: 'center',
  }
});

const TranscribeOutput = ({classes, transcribedText, interimTranscribedText}) => {
  //if (transcribedText.length === 0 && interimTranscribedText.length === 0) {
  //  return <Typography variant="body1"></Typography>;
  //}

  return (
    <div className={classes.root}>
      <Typography align="center" className={classes.outputText} variant="overline">{transcribedText}</Typography>
      <Typography className={classes.outputText} variant="body1">{interimTranscribedText}</Typography>
    </div>
  )
}

export default withStyles(useStyles)(TranscribeOutput);
