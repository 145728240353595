import React from "react";
import styled from "styled-components";
import { IoClose, IoSearch } from "react-icons/io5";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useClickOutside } from "react-click-outside-hook";
import { useEffect } from "react";
import { useRef } from "react";
//import MoonLoader from "react-spinners/MoonLoader";
import { useDebounce } from "../../hooks/debounceHook";
import axios from "axios";
import { TvShow } from "../tvShow";
import HashLoader from "react-spinners/HashLoader";

const SearchBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 4.8em;
  width: 97.5%;//104em;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.14);
  margin-left: 14px;
  margin-right: 12px;
`;

const SearchInputContainer = styled.div`
  width: 100%;
  min-height: 5em;
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 15px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-size: 21px;
  color: #12112e;
  font-weight: 500;
  border-radius: 6px;
  background-color: transparent;

  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    color: #bebebe;
    transition: all 250ms ease-in-out;
  }
`;

const SearchIcon = styled.span`
  color: #bebebe;
  font-size: 28px;
  margin-right: 10px;
  margin-top: 6px;
  vertical-align: middle;
`;

const CloseIcon = styled(motion.span)`
  color: #bebebe;
  font-size: 27px;
  margin-right: 2px;
  margin-top: 6px;
  vertical-align: middle;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: #dfdfdf;
  }
`;

const LineSeperator = styled.span`
  display: flex;
  min-width: 100%;
  min-height: 2px;
  background-color: #d8d8d878;
`;

const SearchContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow-y: auto;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WarningMessage = styled.span`
  color: #bebebe;
  font-size: 20px;
  display: flex;
  align-self: center;
  justify-self: center;
`;

const containerVariants = {
  expanded: {
    height: "24em",
  },
  collapsed: {
    height: "4.8em",
  },
};

const containerTransition = { type: "spring", damping: 24, stiffness:140 };

export function SearchBar({setVideoListFunc}) {
  const [isExpanded, setExpanded] = useState(false);
  const [parentRef, isClickedOutside] = useClickOutside();
  const inputRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [tvShows, setTvShows] = useState([]);
  const [noTvShows, setNoTvShows] = useState(false);

  const isEmpty = !tvShows || tvShows.length === 0;

  const changeHandler = (e) => {
    e.preventDefault();
    if (e.target.value.trim() === "") setNoTvShows(false);
    console.log("change handler called");
    expandContainer();
    setSearchQuery(e.target.value);
  };

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery("");
    setLoading(false);
    setNoTvShows(false);
    setTvShows([]);
    if (inputRef.current) inputRef.current.value = "";
  };

  useEffect(() => {
    if (isClickedOutside) collapseContainer();
  }, [isClickedOutside]);

  const prepareSearchQuery = (query) => {
    query = query.replaceAll(' ','+');
    //const url = `http://api.tvmaze.com/search/shows?q=${query}`;
    const url = `https://l7h5gdbzlb.execute-api.us-west-1.amazonaws.com/prod/${query}`;
    //const url = 'http://127.0.0.1:8000/api/spiritual_questions/${query}';
    //const url = 'http://127.0.0.1:8000/api/spiritual_questions/meditation';
    //const url = 'http://127.0.0.1:8000/api/spiritual_questions/${query}';
    return encodeURI(url);
  };

  const prepareAnswerQuery = (query) => {
    query = query.replaceAll(' ','+');
    //const url = `http://api.tvmaze.com/search/shows?q=${query}`;
    const url = `https://mwlv0vbgck.execute-api.us-west-1.amazonaws.com/prod_2/${query}`;
    //const url = 'http://127.0.0.1:8000/api/spiritual_questions/${query}';
    //const url = 'http://127.0.0.1:8000/api/spiritual_questions/meditation';
    //const url = 'http://127.0.0.1:8000/api/spiritual_questions/${query}';
    return encodeURI(url);
  };

  const searchTvShow = async () => {
    if (!searchQuery || searchQuery.trim() === "") return;

    setLoading(true);
    setNoTvShows(false);

    const URL = prepareSearchQuery(searchQuery);

    const response = await axios.get(URL).catch((err) => {
      console.log("Error: ", err);      
    });

    if (response) {
      console.log("Response: ", response.data);
      if (response.data && response.data.length === 0) setNoTvShows(true);

      setTvShows(response.data);
    }

    setLoading(false);
  };

  const searchAnswers = async (answerQuery) => {
    if (!answerQuery || answerQuery.trim() === "") return;

    const URL = prepareAnswerQuery(answerQuery);
    console.log(answerQuery);
    const response = await axios.get(URL).catch((err) => {
      console.log("Error: ", err);      
    });

    if (response) {
      console.log("Response: ", response.data);
      //if (response.data && response.data.length === 0) setNoTvShows(true);
      setVideoListFunc(response.data);
    }

    setLoading(false);
    collapseContainer();
  };

  // when we press enter on the search bar call this function
  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      console.log('enter press here! ')
      searchAnswers(searchQuery);
    }
  }
  useDebounce(searchQuery, 500, searchTvShow);
//const nameClick = (name) => console.log(name);

  return (
    <SearchBarContainer
      animate={isExpanded ? "expanded" : "collapsed"}
      variants={containerVariants}
      transition={containerTransition}
      ref={parentRef}
    >
      <SearchInputContainer>
        <SearchIcon>
          <IoSearch/>
        </SearchIcon>
        <SearchInput
          placeholder="Enter your spiritual question like - What is equanimity?"
          onFocus={expandContainer}
          ref={inputRef}
          value={searchQuery}
          onChange={changeHandler}
          onKeyPress={handleKeyPress}
        />
        <AnimatePresence>
          {isExpanded && (
            <CloseIcon
              key="close-icon"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={collapseContainer}
              transition={{ duration: 0.2 }}
            >
              <IoClose />
            </CloseIcon>
          )}
        </AnimatePresence>
      </SearchInputContainer>
      {isExpanded && <LineSeperator />}
      {isExpanded && (
        <SearchContent>
          {isLoading && (
            <LoadingWrapper>
              <HashLoader loading color="#88dedd" size={48}/>
            </LoadingWrapper>
          )}
          {!isLoading && isEmpty && !noTvShows && (
            <LoadingWrapper>
              <WarningMessage>type your spiritual question</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading && noTvShows && (
            <LoadingWrapper>
              <WarningMessage>No spiritual answers to your question found!</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading && !isEmpty && (
            <>
              {tvShows.map(({ show }) => (
                <TvShow
                  /*callParent = {() =>nameClick(show.name)}*/
                  callParent = {() =>searchAnswers(show.name)}
                  key={show.id}
                  thumbanilSrc={show.image && show.image.medium}
                  name={show.name}
                  rating={show.rating && show.rating.average}
                />
              ))}
            </>
          )}
        </SearchContent>
      )}
    </SearchBarContainer>
  );
}