import React, { useState, useEffect, useRef } from "react";
//import { Button } from "react-bootstrap";
//import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import 'bootstrap/dist/css/bootstrap.min.css';
//import "./App.css";
import TranscribeOutput from "./TranscribeOutput";
import SettingsSections from "./SettingsSection";
import { ReactMic } from 'react-mic'; //https://www.npmjs.com/package/react-mic
import axios from "axios";
import { PulseLoader } from "react-spinners";
import {motion, AnimatePresence} from "framer-motion";
import FramerWithMaterial from "./WheelOfDhamma"
import { Zoom,Grow,Fade } from "@material-ui/core";
import { Grid } from  "@material-ui/core";
//import {Card, CardActions, CardContent, CardMedia, Paper} from '@material-ui/core';
//import { MeditationVideo } from "/home/avneet/code/repos/FrontEnd/react_app/learn_material_ui_card/src/components/meditationVideo";
import { MeditationVideo } from "../meditationVideo";
import one_bell from "./one_bells.wav";
import fear from "./audioFiles/fear.mp3";
import anxiety from "./audioFiles/anxiety.mp3";
import death from "./audioFiles/death.mp3";
import love from "./audioFiles/love.mp3";
import AyyaKhemaSweeping from "./audioFiles/AyyaKhemaSweeping.mp3";
import BhikkuBodhiLovingKindness from "./audioFiles/BhikkuBodhiLovingKindness.mp3";
import GoenkaJiAnnaPanna from "./audioFiles/GoenkaJiAnnaPanna.mp3";
import GoenkaJiVipassana from "./audioFiles/GoenkaJiVipassana.mp3";
import JoshWelcomeToNibanna from "./audioFiles/JoshWelcomeToNibanna.mp3";
import nature_meditation from "./audioFiles/nature_meditation.mp3";
import heart_break from "./audioFiles/heart_break.mp3";
import ReactGA from "react-ga4";
import { red } from "@material-ui/core/colors";
import textToSpeech from "./tts_11_labs";
import returnStatistics from "./ReturnStatistics";

const useStyles = () => ({
  root: {
    display: 'flex',
    flex: '1',  
    margin: '100px 0px 100px 0px',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',  
  },
  title: {
    marginBottom: '30px',
  },
  settingsSection: {
    marginBottom: '20px',
    display: 'flex',
    width: '100%',
  },
  buttonsSection: {
    marginBottom: '40px',
  },
  recordIllustration: {
    width: '100px',
  }
});

//const App = ({ classes }) => {
//    export function SearchBar({setVideoListFunc}) {
export function VoiceInterface() {
  const classes = useStyles();
  const [transcribedData, setTranscribedData] = useState(['Please click the wheel and Speak. Example: "Hi Nibanna play Vipassana meditation by S. N. Goenka for 60 minutes"']);
  const [interimTranscribedData, ] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [playWelcomeMessage,setPlayWelcomeMessage] = useState(true);
  const [showMainVideo, setShowMainVideo] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [selectedModel, setSelectedModel] = useState(2);
  const [transcribeTimeout, setTranscribeTimout] = useState(16); // This is the amount of time the app waits to send the audio to the server for transcription
  const [stopTranscriptionSession, setStopTranscriptionSession] = useState(false);  
  const [audioURL, setAudioURL] = useState(null);
  // create a const for recordedBlob
  const recordedAudioBlob = useRef(null);
  //var audio_to_play;
  const [audio_to_play, setAudio_to_play] = useState(null);

  const intervalRef = useRef(null);
  const intervalVADRef = useRef(null);
  
  const stopTranscriptionSessionRef = useRef(stopTranscriptionSession);
  stopTranscriptionSessionRef.current = stopTranscriptionSession;

  const selectedLangRef = useRef(selectedLanguage);
  selectedLangRef.current = selectedLanguage;

  const selectedModelRef = useRef(selectedModel);
  selectedModelRef.current = selectedModel;

  const supportedLanguages = ['english', 'chinese', 'german', 'spanish', 'russian', 'korean', 'french', 'japanese', 'portuguese', 'turkish', 'polish', 'catalan', 'dutch', 'arabic', 'swedish', 'italian', 'indonesian', 'hindi', 'finnish', 'vietnamese', 'hebrew', 'ukrainian', 'greek', 'malay', 'czech', 'romanian', 'danish', 'hungarian', 'tamil', 'norwegian', 'thai', 'urdu', 'croatian', 'bulgarian', 'lithuanian', 'latin', 'maori', 'malayalam', 'welsh', 'slovak', 'telugu', 'persian', 'latvian', 'bengali', 'serbian', 'azerbaijani', 'slovenian', 'kannada', 'estonian', 'macedonian', 'breton', 'basque', 'icelandic', 'armenian', 'nepali', 'mongolian', 'bosnian', 'kazakh', 'albanian', 'swahili', 'galician', 'marathi', 'punjabi', 'sinhala', 'khmer', 'shona', 'yoruba', 'somali', 'afrikaans', 'occitan', 'georgian', 'belarusian', 'tajik', 'sindhi', 'gujarati', 'amharic', 'yiddish', 'lao', 'uzbek', 'faroese', 'haitian creole', 'pashto', 'turkmen', 'nynorsk', 'maltese', 'sanskrit', 'luxembourgish', 'myanmar', 'tibetan', 'tagalog', 'malagasy', 'assamese', 'tatar', 'hawaiian', 'lingala', 'hausa', 'bashkir', 'javanese', 'sundanese']
  const boosted_words = 'S. N. Goenka, Goenka, Ayya Khema, Bhikkhu, Bodhi,Tenzin, Palmo, Jetsunma, Krishnamurti, Vipassna, Anna, Panna, Sati, Meditation, body scan, guided, Nibanna, Metta, Metta Meditation, Jhana, Jhana, JapJi, Satti, SattiPathana,sattipathana'
  const modelOptions = ['tiny', 'base', 'small', 'medium', 'large']
  const [meditationVideo, setMeditationVideo] = useState(
    {
      video:"https://www.youtube.com/embed/M2Vnc1Sg2DA",
      teacher: "S N Goenka",
      type: "Vipassana",
      duration: "60 minutes"
      });
  const secondsIntervalToCheckIfSpeechHasStopped = 1;
// create an array of strings
const suggested_voice_commands = ['Please click the wheel and Speak. Example: "Hi Nibanna play Vipassana meditation by S. N. Goenka for 60 minutes"','Please click the wheel and Speak. Example: "Hi Nibanna play Vipassana meditation by S. N. Goenka for 60 minutes"','Please click the wheel and Speak. Example: "Hi Nibanna play Vipassana meditation by S. N. Goenka for 60 minutes"','Please click the wheel and Speak. Example: "Hi Nibanna play Vipassana meditation by S. N. Goenka for 60 minutes"','Please click the wheel and Speak. Example: "Hi Nibanna play Vipassana meditation by S. N. Goenka for 60 minutes"','Please click the wheel and Speak. Example: "Hi Nibanna play Vipassana meditation by S. N. Goenka for 60 minutes"', 'Please click the wheel and Speak. Example: "Hi Nibanna play Anna Panna meditation by S. N. Goenka for 10 minutes"', 'Please click the wheel and Speak. Example: "Hi Nibanna play Sweeping meditation by Ayya Khema for 60 minutes"', 'Please click the wheel and Speak. Example: "Hi Nibanna play Loving Kindness meditation by Bhikkhu Bodhi"', 'Please click the wheel and Speak. Example: "Hi Nibanna play Silence meditation by Nature for sixty minutes"', 'Please click the wheel and Speak. Example: "Hi Nibanna play video on fear"', 'Please click the wheel and Speak. Example: "Hi Nibanna play video on death"', 'Please click the wheel and Speak. Example: "Hi Nibanna play video on anger"', 'Please click the wheel and Speak. Example: "Hi Nibanna play video on heart break"', 'Please click the wheel and Speak. Example: "Hi Nibanna play video on love"', 'Please click the wheel and Speak. Example: "Hi Nibanna play video on anxiety"'];
var number_of_continuous_interactions_with_the_agent = 0;

// use useeffect to call reactga 
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "main_page", title: "Voice Interface Component Loaded in the webpage" });
  //  ReactGA.pageview(window.location.pathname);
    }, []);

  useEffect(() => {
    setTranscribedData(suggested_voice_commands[Math.floor(Math.random() * suggested_voice_commands.length)]);
    return () => clearInterval(intervalRef.current);
  }, []);
  // play welcome message when the page loads
  useEffect(() => {
    welcome_message();
  }, []); 
  useEffect(() => 
  {
    // if localStorage item totalNumberOfVisits does not exist then create with value 0
    if (localStorage.getItem("totalNumberOfVisits") === null) 
    {
      localStorage.setItem("totalNumberOfVisits","0")
    }
    else
    {
      // increase the value of totalNumberOfVisits by 1
      var totalNumberOfVisits = (parseInt(localStorage.getItem('totalNumberOfVisits'))+1);
      localStorage.setItem("totalNumberOfVisits", totalNumberOfVisits.toString());
    }
  }, []);


  const video = {
    video:"https://www.youtube.com/embed/Te7U6ZkwIkI",
    teacher: "S N Goenka",
    type: "Vipassana",
    duration: "60 minutes"
    }

    /*
    // a useffect that will convert playWelcomeMessage to false
    useEffect(() => {
      //setPlayWelcomeMessage(true)
      console.log("playWelcomeMessageuseEffect",playWelcomeMessage)  
      //var promise = document.querySelector('video').play();
      var audio = new Audio(anxiety);
      var promise = audio.play();
      
      if (promise !== undefined) {
        promise.then(_ => {
          setPlayWelcomeMessage(false)
          console.log(" *** Autoplay started! *** ")
        }).catch(error => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          console.log(" **** Autoplay was prevented. **** ")
          setPlayWelcomeMessage(true)
        });
}
    }, []);
 */ 
    function welcome_message() 
    {
      if (localStorage.getItem("totalNumberOfVisits") === null) 
      {
        localStorage.setItem("totalNumberOfVisits","0")
      }
      // play welcome message only if a random integer between 0 and totalNumberOfVisits is less than or equal to 1
      // this will make sure that welcome message is played only once in totalNumberOfVisits times
      var randomInteger = Math.floor(Math.random() * parseInt(localStorage.getItem('totalNumberOfVisits')));
      console.log("randomInteger",randomInteger)
      var playWelcomeMessageBool = randomInteger <= 1;
      console.log("playWelcomeMessageBool",playWelcomeMessageBool)
      console.log("playWelcomeMessageOutsideOfIf",playWelcomeMessage)

      if(playWelcomeMessage && playWelcomeMessageBool)
      {
        setPlayWelcomeMessage(false) 
        //playWelcomeMessage = false; 
        console.log("playWelcomeMessage",playWelcomeMessage)
        // create an array of audio file
      var mp3s = [];
      mp3s[0] = anxiety;
      mp3s[1] = love;
      mp3s[2] = fear;
      mp3s[3] = death;
      mp3s[4] = GoenkaJiAnnaPanna;
      mp3s[5] = AyyaKhemaSweeping;
      mp3s[6] = AyyaKhemaSweeping;
      mp3s[7] = BhikkuBodhiLovingKindness;
      mp3s[8] = GoenkaJiAnnaPanna;
      mp3s[9] = GoenkaJiVipassana ;
      mp3s[10] = JoshWelcomeToNibanna;
      mp3s[11] = nature_meditation;
      mp3s[12] = heart_break;
            
      // randomly select one of the file in the array audio files
      const len = mp3s.length;
      var index = (Math.floor(Math.random() * len));
      //var audio = new Audio(audio_file_to_play);
      var audio = new Audio(mp3s[index]);
        audio.play();
        console.log(" *** welcome message *** ")
      }
    }

    async function generate_tts_11_labs(text_to_speak) 
    {
        console.log(" ### 11 labs tts function ran ### ")
        // Define a function to fetch the audio data and set the URL state variable
        //const handleAudioFetch = async () => {
        // Call the textToSpeech function to generate the audio data for the text "Hello welcome"
        const data = await textToSpeech(text_to_speak);
        console.log(" ### textToSpeech data recieved ### ")
        // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
        const blob = new Blob([data], { type: 'audio/mpeg' });
        // Create a URL for the blob object
        const url = URL.createObjectURL(blob);
        // Set the audio URL state variable to the newly created URL
        setAudioURL(url);
        // Create a new Audio object and play it
        var audio0 = new Audio(url);
        console.log(" ### new audio created ### ")
        //audio0.play();
        console.log(" ### audio played ### ")
        return new Promise((resolve) => {
          resolve(audio0);
        });
          //audio0.play().then(() => {
          //  console.log(" ### audio played ### ");
          //  audio0.onended = resolve;
          //  console.log(" ### promise resolved ### ");
          //}).catch((error) => {
          //  console.error("Audio play failed due to ", error);
          //});
          //audio0.play();
          //console.log(" ### audio played in promise ### ")
          //audio0.onended = resolve;
          //console.log(" ### promise resolved ### ")
        //});
      //};   
      //handleAudioFetch();     
    }
  // Use the useEffect hook to call the handleAudioFetch function once when the component mounts
  useEffect(() => {
    //generate_tts_11_labs("Good. You have completed 60 minutes of meditation."+returnStatistics())
    //console.log(" ### 11 labs tts played ### ")
  }, []);  
/*   function generate_tts(text_to_speak) 
  {
    const selectedVoice = "21m00Tcm4TlvDq8ikWAM";
    const text = text_to_speak;
    try
    {
      if(!text || text.trim() === "")
      {
        console.log("Text to speak is empty");
        return;
      }
      fetch()
    }
    catch (error)
    {
      console.log(error);
    }    
  }
 */
  function play_one_bell(time_in_minutes) {
    // play the bell after the time_in_minutes given in the argument
    // {'sixty_minutes':'60 Minutes','fifteen_minutes':'15 Minutes','ten_minutes':'10 Minutes'}
    if (time_in_minutes == '0 minutes') {
      var audio = new Audio(one_bell);
      audio.play();
      ReactGA.send({ hitType: "pageview", page: "main_page", title: "0 Minutes" });
    }
    else if (time_in_minutes == '10 Minutes') {
      var audio = new Audio(one_bell);
      audio.play();
      setTimeout(function(){ 
        audio.play();
        ReactGA.send({ hitType: "pageview", page: "main_page", title: "10 Minutes" });
      }, 10*60*1000);
      setTimeout(function(){
        generate_tts_11_labs("Good. You have completed 10 minutes of meditation."+returnStatistics());
        console.log(" ### 10 minutes have passed ### ")
      }, 10*60*1000+10000);
    }
    else if (time_in_minutes == '15 Minutes') {
      var audio = new Audio(one_bell);
      audio.play();
      setTimeout(function(){ 
        audio.play();
        ReactGA.send({ hitType: "pageview", page: "main_page", title: "15 Minutes" });
      }, 15*60*1000);
      setTimeout(function(){ 
        generate_tts_11_labs("Good. You have completed 15 minutes of meditation."+returnStatistics());
        console.log(" ### 15 minutes have passed ### ")
      }, 15*60*1000+10000);
    }
    else if (time_in_minutes == '30 Minutes') {
      var audio = new Audio(one_bell);
      audio.play();
      setTimeout(function(){ 
         audio.play();
         ReactGA.send({ hitType: "pageview", page: "main_page", title: "30 Minutes" });
        }, 30*60*1000);
      setTimeout(function(){ 
        generate_tts_11_labs("Good. You have completed 30 minutes of meditation."+returnStatistics());
        console.log(" ### 30 minutes have passed ### ")
      }, 30*60*1000+10000);  
    }
    else if (time_in_minutes == '60 Minutes') {
      var audio = new Audio(one_bell);
      audio.play();
      setTimeout(function(){ 
         audio.play();
         ReactGA.send({ hitType: "pageview", page: "main_page", title: "60 Minutes" });
      }, 60*60*1000);
      setTimeout(function(){ 
        generate_tts_11_labs("Good. You have completed 60 minutes of meditation."+returnStatistics());
        console.log(" ### 60 minutes have passed ### ")
      }, 60*60*1000+10000);
    }
    //var audio = new Audio(one_bell);
    //audio.play();
  }
  
  function handleTranscribeTimeoutChange(newTimeout) {
    setTranscribeTimout(newTimeout)
  }

  function setNumberOfContinuousInteractionsWithTheAgentToZero() {
    number_of_continuous_interactions_with_the_agent = 0;
  }

  function startRecording() {
    console.log('start recording: '+new Date().toLocaleTimeString())
    // console log current time
    setStopTranscriptionSession(false)
    setIsRecording(true)
    localStorage.setItem("speech_happening_now","true")
    intervalVADRef.current = setInterval(checkIfSpeechHasStopped, secondsIntervalToCheckIfSpeechHasStopped*1000);
    intervalRef.current = setTimeout(transcribeInterim, transcribeTimeout * 1000)
  }

  function stopRecording() {
    console.log('stop recording: '+new Date().toLocaleTimeString())
    clearInterval(intervalRef.current);
    clearInterval(intervalVADRef.current);
    setStopTranscriptionSession(true)
    setIsRecording(false)
    setIsTranscribing(false)
  }

  function onData(recordedBlob) {
    // a potential function to run a vad model on the recordedBlob
    console.log('***** onData:*****: '+new Date().toLocaleTimeString());
  }

  function onStop(recordedBlob) {
    console.log('onStop: : '+new Date().toLocaleTimeString());
    //transcribeRecording(recordedBlob)
    // set recordedAudioBlob to the recordedBlob
    console.log('setting recordedAudioBlob to recordedBlob: ', recordedBlob);
    recordedAudioBlob.current = recordedBlob;
    console.log('recordedAudioBlob: ', recordedAudioBlob);
    setIsTranscribing(true)  
  }

  // a function that will check every 1 second if the user has stopped speaking
  // using localStorage "speech_happening_now". If the value of "speech_happening_now" is false, then call transcribeInterim
  function checkIfSpeechHasStopped() {
    console.log("checkIfSpeechHasStopped: "+new Date().toLocaleTimeString())
    // if localStorage item speech_happening_now does not exist then create with value false
    if (localStorage.getItem("speech_happening_now") === null) 
    {
      localStorage.setItem("speech_happening_now","false")
    }
    // if the value of localStorage item speech_happening_now is false, then call transcribeInterim
    if (localStorage.getItem("speech_happening_now") === "false") 
    {
      console.log("speech has stopped. Calling transcribeInterim: "+new Date().toLocaleTimeString())
      // clear intervalVADRef.current
      clearInterval(intervalVADRef.current);
      // clear intervalRef.current
      clearInterval(intervalRef.current);
      transcribeInterim()
    }
  }

  function transcribeInterim() {
    console.log('transcribeInterim: '+new Date().toLocaleTimeString())
    clearInterval(intervalRef.current);
    clearInterval(intervalVADRef.current);
    setIsRecording(false)
  }
  // a function that will wait until recordedAudioBlob is not null and then return the blob
    
  //let recordedAudioBlob1 = null; // This should be your actual blob reference

  function waitForBlob() {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (recordedAudioBlob.current !== null) {
          console.log('Blob is ready:', recordedAudioBlob.current);
          const blob = recordedAudioBlob.current;
          // Set recordedAudioBlob to null
          recordedAudioBlob.current = null;
          clearInterval(intervalId);
          resolve(blob);
        } else {
          console.log('Blob is not ready:', recordedAudioBlob);
        }
      }, 1000); // Check every 100 milliseconds
    });
  }
  function playAudio(audio){
    return new Promise(res=>{
      audio.play()
      audio.onended = res
    })
  }
 async function transcribeRecording() {
    console.log('transcribeRecording: '+new Date().toLocaleTimeString())
    ReactGA.event({
      action: "Voice Interface Transcription Started",
      category: "Speech Transcription",
    });
    console.log('transcribeRecording: '+new Date().toLocaleTimeString())
    //const soundEffect = new Audio();
    //soundEffect.autoplay = true;

    // onClick of first interaction on page before I need the sounds
    // (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
    //soundEffect.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
    // set soundEffect.src to the audio file for anxiety
    //soundEffect.src = anxiety;
    //var message = "HI NIBANNA PLAY VIDEO ON fear";
    //var audioData1 = await generate_tts_11_labs(message);
    //audioData1.play();
    var audio_one_bell = new Audio(one_bell);
    var tts_11_labs_audio = new Audio();
    var audioData = new Audio();
    var endTheWhileLoop = false;
    while(endTheWhileLoop === false && number_of_continuous_interactions_with_the_agent < 8){
    console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~endTheWhileLoop:",endTheWhileLoop)
    console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~number_of_continuous_interactions_with_the_agent:",number_of_continuous_interactions_with_the_agent)
      // start the recording
    startRecording()
    // wait until recordedAudioBlob is not null
    // declare recordedBlob
    //var recordedBlob = recordedAudioBlob.current;
    // Usage
    // create a variable to store the blob
    
    const recordedBlob = await waitForBlob();
    console.log('waitForBlob() is complete. Blob is ready:', recordedBlob);
    //  console.log('Blob is ready:', blob);
    //});
    // create a form data
    const headers = {
      "content-type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("language", selectedLangRef.current)
    formData.append("boosted_words", boosted_words)
    // if localStorage does not have a UUID then create one and add it to the form as session_id
    if (localStorage.getItem("session_id") === null)
    {
      localStorage.setItem("session_id", Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))
    }
    // add session_id to the form data
    formData.append("session_id", localStorage.getItem("session_id"))
    formData.append("model_size", modelOptions[selectedModelRef.current])
    console.log('sending recordedBlob.blob with the form using axios: ', recordedBlob.blob);
    console.log('sending recordedBlob with the form using axios: ', recordedBlob);
    formData.append("audio_data", recordedBlob.blob, 'temp_recording');
    console.log('sending formData: ', formData);
    //var res = await axios.post("http://0.0.0.0:8000/transcribe", formData, { headers })
    //axios.post("http://ec2-54-215-206-81.us-west-1.compute.amazonaws.com:8000/transcribe", formData, { headers })
    //axios.post("http://ec2-54-193-76-73.us-west-1.compute.amazonaws.com:8000/transcribe", formData, { headers })
    //uncomment the one below for production
    var res = await axios.post("https://rc8dvw3gjf.execute-api.us-west-1.amazonaws.com/transcribe", formData, { headers })
    // send ReactGA message that the transcription and llm message has been recieved
    ReactGA.event({
      action
      : "Voice Interface Transcription Recieved",
      category: "Speech Transcription",
    });
    
    var audioDataResponse = await generate_tts_11_labs(res.data.message);
    //assign the audioData2 to audioData1
    audioData.src = audioDataResponse.src;
    //audioData.play();
    await playAudio(audioData)
    setIsTranscribing(false)
    //soundEffect.src = audioData1;
    //audio_to_play = audioData1;
    //axios.post("https://rc8dvw3gjf.execute-api.us-west-1.amazonaws.com/transcribe", formData, { headers })
    //.then(async (res) => {
      
    console.log("axios post worked")
      
      //audioData = await generate_tts_11_labs(res.data.message);
      //audioData.play();
      // soundEffect.src to audioData
      //soundEffect.src = audioData;
      // play the sound effect
      //soundEffect.play();
      //audioData.play();

      console.log("played the message from the server"+res.data.message)
      // show facemesh only for meditation videos
      if(res.data.meditation_type)
        {
          sessionStorage.setItem("showFaceMeshFlag",true)
          console.log(" ################ showFaceMeshFlag set to true due to meditation video")
        }
        else
        {
          sessionStorage.setItem("showFaceMeshFlag",false)
          console.log(" ################ showFaceMeshFlag set to false due to no meditation video")
        }
      // if the data has either meditation_type or mental_condition_or_life_situation or spiritual_question then set setShowMainVideo(true) else set setShowMainVideo(false) 
      if(res.data.meditation_type || res.data.mental_condition_or_life_situation || res.data.spiritual_question)
      {
        setShowMainVideo(true)
        endTheWhileLoop = true;
        console.log("~~~~~~~~~~~~~~~~~~~ endTheWhileLoop(true) ~~~~~~~~~~~~~~~~~~~~~~:",endTheWhileLoop)
        console.log("res.data.meditation_type: ",res.data.meditation_type)
        console.log("res.data: ",res.data)
        
      }
      else
      {
        setShowMainVideo(false)
        // if number_of_continuous_interactions_with_the_agent is less than 8 then call startRecording
        if(number_of_continuous_interactions_with_the_agent < 8)
        {
          //startRecording()
          //transcribeRecording()
          number_of_continuous_interactions_with_the_agent = number_of_continuous_interactions_with_the_agent + 1;
        }
      }
    
      //setShowMainVideo(true)
        //setTranscribedData(oldData => [...oldData, res.data])
        // if res.data.type exists, then set the meditation video to the one that is returned
        // if not results are found, then return no results found, try again
        // This is the type of json object that is returned from the server
        //{
          //"message":"Sure, let's explore what spiritual teachers have to say about the concept of evil. The video should be playing for you now.",
          //"video_id":"UZetvXoHb7s",
          //"start_time":"0",
          //"teacher":"S. N. Goenka",
          //"duration":null,
          //"meditation_type":null,
          //"mental_condition_or_life_situation":null,
          //"spiritual_question":"How Buddha's teachings were kept undamaged over these centuries?"
        //}
        if(res.data.meditation_type){
          console.log(res.data)
          // check if sessionStorage item showFaceMeshFlag exists, if not create it and set it to true
          //sessionStorage.setItem("showFaceMeshFlag",true)
          //console.log(" ################ showFaceMeshFlag set to true due to meditation video")
          // if red.data.teacher === "no matches found, please try again"
          if(res.data.teacher === "no matches found, please try again")
          {
            //console.log('res.data.teacher')
            setTranscribedData(['No results found, please try again'])
            setMeditationVideo(res.data)
          }
          else
          {
            setTranscribedData(['Playing ', res.data.meditation_type,' meditation by ',res.data.teacher,' for ', res.data.duration])
            play_one_bell('0 minutes')
            // wait 12 seconds before starting the next recording
            setTimeout(() => {
              setMeditationVideo(res.data)
            }, 0*1000);
            //play_one_bell(res.data.duration)
            var time_in_minutes = res.data.duration;
            console.log("time_in_minutes",time_in_minutes)
            // to play the audio file in iPhone the audio has to be created in this function
                // play the bell after the time_in_minutes given in the argument
            // {'sixty_minutes':'60 Minutes','fifteen_minutes':'15 Minutes','ten_minutes':'10 Minutes'}
            if (time_in_minutes == '0 minutes') {
              //var audio_one_bell = new Audio(one_bell);
              audio_one_bell.play();
              ReactGA.send({ hitType: "pageview", page: "main_page", title: "0 Minutes" });
            }
            else if (time_in_minutes == '10 Minutes') {
              console.log("10 minutes",time_in_minutes)
              //var audio = new Audio(one_bell);
              audio_one_bell.play();
              setTimeout(function(){ 
                //audio_one_bell.play();
                var bell = new Audio(one_bell);
                bell.play();
                audio_one_bell.src = bell.src;
                audio_one_bell.play();
                ReactGA.send({ hitType: "pageview", page: "main_page", title: "10 Minutes" });
              }, 10*60*1000);
              //var audioDataResponse_;
              setTimeout(async function(){
                var audioDataResponse_ = await generate_tts_11_labs("Good. You have completed 10 minutes of meditation."+returnStatistics());
                //audioDataResponse_.play();
                tts_11_labs_audio.src = audioDataResponse_.src;
                tts_11_labs_audio.play();  
                console.log(" ### 10 minutes have passed ### ")
              }, 10*60*1000+10000);
              //audioData.src = audioDataResponse.src;
              //audioData.play();
              //await playAudio(tts_11_labs_audio)
            }
            else if (time_in_minutes == '15 Minutes') {
              //var audio = new Audio(one_bell);
              audio_one_bell.play();
              setTimeout(function(){ 
                //audio_one_bell.play();
                var bell = new Audio(one_bell);
                bell.play();
                audio_one_bell.src = bell.src;
                audio_one_bell.play();
                ReactGA.send({ hitType: "pageview", page: "main_page", title: "15 Minutes" });
              }, 15*60*1000);
              setTimeout(async function(){ 
                var audioDataResponse_ = await generate_tts_11_labs("Good. You have completed 15 minutes of meditation."+returnStatistics());
                //audioDataResponse_.play();
                tts_11_labs_audio.src = audioDataResponse_.src;
                tts_11_labs_audio.play();  
                console.log(" ### 15 minutes have passed ### ")
              }, 15*60*1000+10000);
              //audioData.src = audioDataResponse.src;
              //audioData.play();
              //await playAudio(tts_11_labs_audio)
            }
            else if (time_in_minutes == '30 Minutes') {
              //var audio = new Audio(one_bell);
              audio_one_bell.play();
              setTimeout(function(){ 
                audio_one_bell.play();
                ReactGA.send({ hitType: "pageview", page: "main_page", title: "30 Minutes" });
                }, 30*60*1000);
              setTimeout(async function(){ 
                var audioDataResponse_ = await generate_tts_11_labs("Good. You have completed 30 minutes of meditation."+returnStatistics());
                //audioDataResponse_.play();
                tts_11_labs_audio.src = audioDataResponse_.src;
                tts_11_labs_audio.play();  

                //generate_tts_11_labs("Good. You have completed 30 minutes of meditation."+returnStatistics());
                console.log(" ### 30 minutes have passed ### ")
              }, 30*60*1000+10000);  
            }
            else if (time_in_minutes == '60 Minutes') {
              //var audio = new Audio(one_bell);
              audio_one_bell.play();
              setTimeout(function(){ 
                audio_one_bell.play();
                ReactGA.send({ hitType: "pageview", page: "main_page", title: "60 Minutes" });
              }, 60*60*1000);
              setTimeout(async function(){ 
                var audioDataResponse_ = await generate_tts_11_labs("Good. You have completed 60 minutes of meditation."+returnStatistics());
                //audioDataResponse_.play();
                tts_11_labs_audio.src = audioDataResponse_.src;
                tts_11_labs_audio.play();  

                console.log(" ### 60 minutes have passed ### ")
              }, 60*60*1000+10000);
            }
          }
      }
      else if(res.data.mental_condition_or_life_situation){
        setTranscribedData(['Playing video on ', res.data.mental_condition_or_life_situation, ' by ',res.data.teacher])
        setMeditationVideo(res.data)
        //sessionStorage.setItem("showFaceMeshFlag",false)
        //console.log(" ~~~~~~~~~~~~~~~~~~~~~ showFaceMeshFlag set to false due to mental_condition_or_life_situation")
      }
      else if(res.data.spiritual_question){
        setTranscribedData(['Playing video on ', res.data.spiritual_question, ' by ',res.data.teacher])
        setMeditationVideo(res.data)
        //sessionStorage.setItem("showFaceMeshFlag",false)
        //console.log(" %%%%%%%%%%%%%%%%%%%%%%% showFaceMeshFlag set to false due to spiritual_question")
      }
        setIsTranscribing(false)
        intervalRef.current = setTimeout(transcribeInterim, transcribeTimeout * 1000)
      //});
      {/** 
      if (!stopTranscriptionSessionRef.current){
        setIsRecording(true)    
      }
      */}
  }
}
  return (
    <div className={classes.root}>
      <Grid style={{marginTop:'148px'}} container direction="column" alignItems="center" justifyContent="center" spacing={2}>
      <Grid item xs = {16} alignItems="center" justifyContent="center"  style={{marginBottom:'54px', marginTop: '32px'}}>
      <div  style={{cursor:'pointer'}}>
        {/** #e6e6e6 is probably the background of the wheel of dhamma */}
        {!isRecording && !isTranscribing && <motion.img src={"./DharmaWheel.png"} animate = {{rotate: 360*4}} transition = {{repeat: Infinity, duration: 24*4, repeatDelay: 0.0,delay: 0,repeatType:"loop"}} style={{ scale : 0.9 }} onClick={()=>{transcribeRecording();setNumberOfContinuousInteractionsWithTheAgentToZero();}} /** onMouseEnter={() => welcome_message()}*/ />}
        {(isRecording || isTranscribing) && <motion.img src={"./DharmaWheel.png"} animate = {{rotate: -360*4}} transition = {{repeat: Infinity, duration: 24*4}} style={{scale: 0.9}} onClick={stopRecording} disabled={stopTranscriptionSessionRef.current}/>}
      </div>
      </Grid>
{/*       <motion.img src={"./favicon.ico"} animate = {{rotate: [0,360]}} transition = {{repeat: Infinity, duration: 10}} style={{ scale: 0.3}} /> */}
{/*      <FramerWithMaterial></FramerWithMaterial> */}
{/*
      <div className={classes.title}>
        <Typography variant="h3"
          Whisper Playground <span role="img" aria-label="microphone-emoji">🎤</span>
        </Typography>
      </div>
  */}
{/** 
      <div className={classes.settingsSection}>
        <SettingsSections disabled={isTranscribing || isRecording} possibleLanguages={supportedLanguages} selectedLanguage={selectedLanguage}
          onLanguageChange={setSelectedLanguage} modelOptions={modelOptions} selectedModel={selectedModel} onModelChange={setSelectedModel}
          transcribeTimeout={transcribeTimeout} onTranscribeTiemoutChanged={handleTranscribeTimeoutChange} />
      </div>
      <div className={classes.buttonsSection} >
        {!isRecording && !isTranscribing && <Button onClick={startRecording} variant="primary">Start transcribing</Button>}
        {(isRecording || isTranscribing) && <Button onClick={stopRecording} variant="danger" disabled={stopTranscriptionSessionRef.current}>Stop</Button>}
      </div>
*/}
{/*       
        <motion.img src="/photo.jpg" width={250} height={250} alt="" onClick={startRecording}></motion.img>

<img src="/photo.jpg" width={250} height={250} alt="" onClick={startRecording}></img>
*/}
      {/* https://www.youtube.com/watch?v=GOuwOI-WSkE */}
      <Grid item xs = {12} alignItems="center" justifyContent="center">
      <div className="recordIllustration">
        <ReactMic width={1} height={1} record={isRecording} className="sound-wave" onStop={onStop}
          onData={onData}  visualSetting="sinewave" strokeColor="#ffffff" backgroundColor="#ffffff"
          channelCount={1} echoCancellation={true} noiseSuppression={true} sampleRate={16000}
          mimeType="audio/wav" autoGainControl={false}
          />
{/*frequencyBars strokeColor="#0d6efd" backgroundColor="#f6f6ef"*/} 
      </div>
</Grid >
{/** */}
<Grid item xs = {16} alignItems="center" justifyContent="center" style={{marginBottom:'32px', marginTop: '20px'}}>
      <div>
        <TranscribeOutput transcribedText={transcribedData} interimTranscribedText={interimTranscribedData} />
        <PulseLoader sizeUnit={"px"} size={20} color="#bebebe" loading={isTranscribing} />
      </div>
</Grid>
<Grid item xs = {16} alignItems="center" justifyContent="center" style={{marginBottom:'6px', marginTop: '0px'}}>
  <AnimatePresence>
      <motion.div     
        initial={{ opacity: 0, scale: 0.4}}
        animate={{ opacity: [0,1], scale: [0.4, 1]}}
        exit={{ opacity: 0, scale: 0 }}
        transition={{ delay:0, duration:40 }}>
      {showMainVideo && <MeditationVideo video={meditationVideo} showFaceMeshFlag={true}/>}
      </motion.div>
  </AnimatePresence>
      </Grid>
      </Grid>
    </div>  
  );
}
//<div>
//          <MeditationVideo/> // videoList={videoList}/>
//</div>
//export default withStyles(useStyles)(VoiceInterface);
