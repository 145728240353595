import axios from 'axios';

// Define a function called textToSpeech that takes in a string called inputText as its argument.
const textToSpeech = async (inputText) => {
    console.log("textToSpeech called **************");
  // Set the API key for ElevenLabs API. 
  // Do not use directly. Use environment variables.
  const API_KEY = "d3b87bd9ff69fc4c11892301e46319a6";
  // Set the ID of the voice to be used.
  //  {"voice_id":"LcfcDJNUP1GQjkzn1xUU","name":"Emily"} & {"voice_id":"GBv7mTt0atIp3Br8iCZE","name":"Thomas"}
  const VOICE_ID = 'LcfcDJNUP1GQjkzn1xUU'; // "name":"Emily"

  // Set options for the API request.
  const options = {
    method: 'POST',
    url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
    headers: {
      accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
      'content-type': 'application/json', // Set the content type to application/json.
      'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
    },
    data: {
      text: inputText, // Pass in the inputText as the text to be converted to speech.
    },
    responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
  };

  // Send the API request using Axios and wait for the response.
  const speechDetails = await axios.request(options);
  console.log(" ********* 11 labs response recieved **************");
  // Return the binary audio data received from the API response.
  return speechDetails.data;
  
};

// Export the textToSpeech function as the default export of this module.
export default textToSpeech;