import React, { useEffect } from "react";
import {Typography, Card, CardContent, CardMedia, CssBaseline, Grid, Container} from '@material-ui/core';
import useStyles from "../../styles";
import { useState } from "react";
import  MPFaceMesh  from "../mpFaceMesh";
import { makeStyles } from "@material-ui/core/styles";

// to autoplay the video add ?autoplay=1 to the end of the url
// to start the video at a certain time add ?start=seconds to the end of the url
// to end the video at a certain time add ?end=seconds to the end of the url
// https://www.youtube.com/embed/NvpTp_soGTs?autoplay=1

const useStyles1 = makeStyles((theme) => ({
    video: {
        width: "100%",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
}));

//const App = () => 
export function MeditationVideo({video, showFaceMeshFlag=true}) 
{
    const classes = useStyles();
    var user_agent = navigator.userAgent.toLowerCase();
    var isMobile = false;
    var isTablet = false;
    var isDesktop = true;
    // define the width in pexels for each device
    var card_height = "2000px";
    var card_width = "1298px";
    var paddingTopVideo = "810px"
    var showFaceMesh = false;
    // check if sessionStorage has the value of showFaceMeshFlag, if it does then set showFaceMesh to that value
    if(sessionStorage.getItem("showFaceMeshFlag") != null)
    {
        showFaceMesh = sessionStorage.getItem("showFaceMeshFlag") === 'true';
        console.log("****showFaceMesh being set the value from sessionStorage: " + showFaceMesh);
        if(!showFaceMesh)
            {
                card_height = "1000px";
                paddingTopVideo = "64px"//"108px"
            }
    }
    
    // if the users agent has the word iphone or android in it, then it is a mobile device
    if(user_agent.indexOf("iphone") != -1 || user_agent.indexOf("android") != -1){isMobile = true;card_height = "100%";card_width = "100%";}
    // if the users agent has the word ipad or tablet in it, then it is a tablet device
    if(user_agent.indexOf("ipad") != -1 || user_agent.indexOf("tablet") != -1){isTablet = true;}
    if(isMobile || isTablet){isDesktop = false;}
    //console.log("isMobile: " + isMobile);
    //console.log("isTablet: " + isTablet);
    //console.log("isDesktop: " + isDesktop);
// This is the type of json object that is returned from the server
        //{
          //"message":"Sure, let's explore what spiritual teachers have to say about the concept of evil. The video should be playing for you now.",
          //"video_id":"UZetvXoHb7s",
          //"start_time":"0",
          //"teacher":"S. N. Goenka",
          //"duration":null,
          //"meditation_type":null,
          //"mental_condition_or_life_situation":null,
          //"spiritual_question":"How Buddha's teachings were kept undamaged over these centuries?"
        //}
    //    const video = {
//        video:"https://www.youtube.com/embed/Te7U6ZkwIkI",
//        teacher: "S N Goenka",
//        type: "Vipassana",
//        duration: "60 minutes"
//    }
        // either there will be a value in meditation_type or mental_condition_or_life_situation or spiritual_question
        // set video.type to the value of the one that is not null
        video.type = video.meditation_type || video.mental_condition_or_life_situation || video.spiritual_question;
        console.log("video.type: " + video.type);
        // add "https://www.youtube.com/embed/" to the beginning of the video_id
        // also add start time and autoplay to the end of the video_id
        // +"?start="+start_time+"&autoplay=1"
        //video.video_id = video.video_id + "?start=" + video.start_time + "&autoplay=1";
        // example: "video": "https://www.youtube.com/embed/"+video_id+"?start="+start_time+"&autoplay=1"
        video.video = "https://www.youtube.com/embed/" + video.video_id + "?start=" + video.start_time + "&autoplay=1";
        console.log("video.video: " + video.video);     
    return (
        <>
         <CssBaseline/>
         <main>
             <Container className={classes.cardGrid} maxWidth="xl" align="center"  style={{paddingTop: "0px",paddingLeft: "0px",paddingRight: "0px",paddingBottom: "0px", border: "0px solid orange"}}>
                        <Card className={classes.card} style={{ height: card_height, width: "card_width",paddingTop: "0px", border: "0px solid brown"}}>
                         {showFaceMesh && (<MPFaceMesh/>)}  
                         {isDesktop && <CardMedia className={classes.cardMedia} component="iframe" image={video.video} allow="autoplay" style={{ height: "2000px", width: "1299px",paddingTop: paddingTopVideo, border: "0px solid blue"}}/>}
                         {(isMobile || isTablet) && <CardMedia className={classes.cardMedia} component="iframe" image={video.video} allow="autoplay" style={{ aspectRatio: "16/9",height: "42%", width: "100%",paddingLeft: "0%", paddingTop: "0px", border: "0px solid red"}}/>}
                             <CardContent className={classes.cardContent}  style={{ paddingTop: "3%",paddingBottom:"0%" }}>
                                 <Typography gutterBottom variant="h5">
                                 {video.teacher}
                                 </Typography>
                                 <Typography>
                                 {video.type}
                                 </Typography>
                                 <Typography>
                                 {video.duration}
                                 </Typography>
                             </CardContent>
                         </Card>
             </Container>
         </main>
        </>
    );
}