import React,{useEffect} from "react";
import Webcam from "react-webcam";
import { FaceMesh, FACEMESH_TESSELATION, FACEMESH_RIGHT_EYE, FACEMESH_RIGHT_EYEBROW, FACEMESH_LEFT_EYE, FACEMESH_LEFT_EYEBROW, FACEMESH_FACE_OVAL, FACEMESH_LIPS } from "@mediapipe/face_mesh/face_mesh";
import { Camera } from "@mediapipe/camera_utils/camera_utils";
import { Container } from "react-bootstrap";
import { Grid, Typography, Button, CardContent, CardMedia, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import textToSpeech from "./tts_11_labs";
import beStill from "./BeStillLikeTheDeepLakeOnTheTopOfTheMountainsEmily.mp3";

const useStyles = makeStyles((theme) => ({
    video: {
        width: "100%",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    gridContainer: {
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    paper_mobile: {
        width: "100%",
        justifyContent: "center",
        position: "static",
        padding: "0px",
        paddingTop: "12px",  
        marginRight: "0px",
        marginLeft: "0px",
        elevation: "0",
    },
    paper: {
        position: "absolute",
        padding: "42px",
        marginRight: "0px",
        marginLeft: "0px",
        elevation: "0",
    },
}));

const MPFaceMesh = () => {
    const classes = useStyles();
    const webcamRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const connect = window.drawConnectors;
    const [isWebCamVisible, setIsWebCamVisible] = React.useState(false);
    var user_agent = navigator.userAgent.toLowerCase();
    var noseX = 0;
    var noseY = 0;
    var noseZ = 0;
    var noseSmoothX = 0;
    var noseSmoothY = 0;
    var noseSmoothZ = 0;
    var deltaNoseX = 0;
    var deltaNoseY = 0;
    var deltaNoseZ = 0;
    var deltaNoseXSmooth = 0;
    var deltaNoseYSmooth = 0;
    var deltaNoseZSmooth = 0;
    var deltaNose = 0;
    var deltaNoseNormalized = 0;
    //var deltaSmoothing = 0.0;
    var eyeDistance = 0;
    var stillness = 100;
    var stillnessSmooth = 100;
    var ratioStillness = 0;
    var stillnessMean = 100;
    //var stillnessMedian = 100;
    var stillnessSum = 0;
    var stillnessCount = 0;
    //var reservoirLength = 10000000;
    // calculate the mean of the ratioStillness and store it in ratioStillnessMean
    //var ratioStillnessSum = 0; // Declare the variable
    //var ratioStillnessCount = 0; // Declare the variable and initialize it to 0
    //var ratioStillnessMean = 0;
    //var ratioStillnessSumOfSquares = 0;
    //var ratioStillnessVariance = 0;
    //var ratioStillnessStdDev = 0;
    var first = true;
    var isMobile = false;
    var isTablet = false;
    var isDesktop = true;
    const [currentStillness, setCurrentStillness] = React.useState(0);
    const [smoothStillness, setSmoothStillness] = React.useState(0);
    const [ratioSmoothStillness, setRatioSmoothStillness] = React.useState(0);
    //const [ratioStillnessStdDeviation, setRatioStillnessStdDeviation] = React.useState(0);
    const [currentStillnessMean, setCurrentStillnessMean] = React.useState(100);
    //const [currentStillnessMedian, setCurrentStillnessMedian] = React.useState(100);
    const [suddenMovementDetected, setSuddenMovementDetected] = React.useState(false);
    const [minutesMeditatedInCurrentSession, setMinutesMeditatedInCurrentSession] = React.useState(0);
    const [hoursMeditatedInTotal, setHoursMeditatedInTotal] = React.useState(0);
    //const [audioURL, setAudioURL] = React.useState(null);
    // create a constant that holds the value for how much time in minutes to wait before alerting the user that they are moving too much
    const [timeToWaitBeforeAlertingUser, setTimeToWaitBeforeAlertingUser] = React.useState(2);
    //    const { loaded, cv } = useOpenCv()

//    var cam_matrix = cv.matFromArray(3, 3);
//    console.log("cam_matrix: " + cam_matrix);
    // console log all attributes of the user agent
    //console.log("This is the user agent: " + user_agent);
    // if the users agent has the word iphone or android in it, then it is a mobile device
    if(user_agent.indexOf("iphone") != -1 || user_agent.indexOf("android") != -1){isMobile = true;}
    // if the users agent has the word ipad or tablet in it, then it is a tablet device
    if(user_agent.indexOf("ipad") != -1 || user_agent.indexOf("tablet") != -1){isTablet = true;}
    if(isMobile || isTablet){isDesktop = false;}
    //console.log("isMobile: " + isMobile);
    //console.log("isTablet: " + isTablet);
    //console.log("isDesktop: " + isDesktop);

    function generate_tts_11_labs(text_to_speak) 
    {
        //console.log(" ### 11 labs tts function ran ### ")
        // Define a function to fetch the audio data and set the URL state variable
        const handleAudioFetch = async () => {
        /* Comment out the following lines to prevent calling 11 labs, instead play a recorded version
        // Call the textToSpeech function to generate the audio data for the text "Hello welcome"
        const data = await textToSpeech(text_to_speak);
        // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
        const blob = new Blob([data], { type: 'audio/mpeg' });
        // Create a URL for the blob object
        const url = URL.createObjectURL(blob);
        // Set the audio URL state variable to the newly created URL
        setAudioURL(url);
        // Create a new Audio object and play it
        var audio0 = new Audio(url);
        //audio0.play();
        */
        var audio = new Audio(beStill);
        audio.play();
      };   
      handleAudioFetch();     
    }


    useEffect(() => {
        const faceMesh = new FaceMesh({
            locateFile: (file) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
            }
        });
        faceMesh.setOptions({
            maxNumFaces: 1,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        });
        faceMesh.onResults(onResults);

        if(typeof webcamRef.current !== "undefined" && webcamRef.current !== null){
            const camera = new Camera(webcamRef.current.video, {
                onFrame: async () => {
                    await faceMesh.send({image: webcamRef.current.video});
                },
                width: 640,
                height: 480
            });
            camera.start();
            localStorage.setItem("minutesMeditatedInCurrentSession","0")
            setInterval(increaseMinutesMeditatedInCurrentSession,60*1000)
            setInterval(increaseHoursMeditatedInTotal,60*1000)
        }
    }, []);

    const increaseMinutesMeditatedInCurrentSession = () => {
        //console.log("increaseMinutesMeditatedInCurrentSession");
        // create a local storage variable for minutes meditated in the current session
        if (localStorage.getItem("minutesMeditatedInCurrentSession") === null)
        {
            localStorage.setItem("minutesMeditatedInCurrentSession","0")
            setMinutesMeditatedInCurrentSession(0);
        }
        // else increase the minutes meditated in the current session by 1
        else
        {
            let minutesMeditatedInCurrentSession = parseInt(localStorage.getItem("minutesMeditatedInCurrentSession"));
            minutesMeditatedInCurrentSession++;
            localStorage.setItem("minutesMeditatedInCurrentSession",minutesMeditatedInCurrentSession.toString());
            setMinutesMeditatedInCurrentSession(minutesMeditatedInCurrentSession);
        }
        };
        const increaseHoursMeditatedInTotal = () => {
            //console.log("increaseHoursMeditatedInTotal");
            // create a local storage variable for minutes meditated in the current session
            if (localStorage.getItem("hoursMeditatedInTotal") === null)
            {
                localStorage.setItem("hoursMeditatedInTotal","0")
                setHoursMeditatedInTotal(0);
            }
            // else increase the minutes meditated in the current session by 1
            else
            {
                let hoursMeditatedInTotal = parseInt(localStorage.getItem("hoursMeditatedInTotal"));
                // increase hoursMeditatedInTotal by a minute
                hoursMeditatedInTotal= hoursMeditatedInTotal + 1;
                localStorage.setItem("hoursMeditatedInTotal",hoursMeditatedInTotal.toString());
                setHoursMeditatedInTotal(hoursMeditatedInTotal);
            }
            };

    const onResults = (results) => 
    {
        const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = 0.77*webcamRef.current.video.videoHeight;
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;
        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext("2d");
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.translate(videoWidth, 0);
        canvasCtx.scale(-1, 1);
        canvasCtx.drawImage(results.image, 0, 0, videoWidth, videoHeight);
        /*
        // variable to set the angle of rotation of the head
        var face_2d = [];
        var points = [1, 33, 263, 61, 291, 199];
        var pointsObj = [ 0,	-1.126865,	7.475604, // nose 1
        -4.445859,	2.663991,	3.173422, //left eye corner 33
         4.445859,	2.663991,	3.173422, //right eye corner 263
         -2.456206,	-4.342621,	4.283884,// left mouth corner 61
         2.456206,	-4.342621,	4.283884,// right mouth corner 291
         0,	-9.403378,	4.264492];//chin
         var width = results.image.width; //canvasElement.width; //
         var height = results.image.height; //canvasElement.height; //results.image.height;
         var roll = 0,
           pitch = 0,
           yaw = 0;
         var x, y, z;
       
         // Camera internals
         var normalizedFocaleY = 1.28; // Logitech 922
         var focalLength = height * normalizedFocaleY;
         var s = 0;//0.953571;
         var cx = width / 2;
         var cy = height / 2;
       
         //var cam_matrix = cv.matFromArray(3, 3, cv.CV_64FC1, [focalLength,s,cx,0,focalLength,cy,0,0,1]);
       
         //The distortion parameters
         //var dist_matrix = cv.Mat.zeros(4, 1, cv.CV_64FC1); // Assuming no lens distortion
         var k1 = 0.1318020374;
         var k2 = -0.1550007612;
         var p1 = -0.0071350401;    
         var p2 = -0.0096747708;
         //var dist_matrix = cv.matFromArray(4, 1, cv.CV_64FC1, [k1, k2, p1, p2]);
         var message = "";
          */
        if(results.multiFaceLandmarks)
        {
            for(const landmarks of results.multiFaceLandmarks){
                connect(canvasCtx, landmarks, FACEMESH_TESSELATION, {color: "#C0C0C0", lineWidth: 1});
                connect(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, {color: "#E0E0E0"});
                connect(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, {color: "#E0E0E0"});
                connect(canvasCtx, landmarks, FACEMESH_LEFT_EYE, {color: "#E0E0E0"});
                connect(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, {color: "#E0E0E0"});
                connect(canvasCtx, landmarks, FACEMESH_FACE_OVAL, {color: "#E0E0E0"});
                //connect(canvasCtx, landmarks, FACEMESH_NOSE, {color: "#E0E0E0"});
                connect(canvasCtx, landmarks, FACEMESH_LIPS, {color: "#E0E0E0"});
                //console.log("nose x : " + landmarks[1].x + " y " + landmarks[1].y + " z " + landmarks[1].z); // nose
                if(first){
                    //noseX = landmarks[1].x;
                    noseSmoothX = landmarks[1].x;
                    //noseY = landmarks[1].y;
                    noseSmoothY = landmarks[1].y;
                    //noseZ = landmarks[1].z;
                    noseSmoothZ = landmarks[1].z;
                    first = false;
                }
                else{
                    noseSmoothX = landmarks[1].x * 0.0001 + noseSmoothX * 0.9999;
                    noseSmoothY = landmarks[1].y * 0.0001 + noseSmoothY * 0.9999;
                    noseSmoothZ = landmarks[1].z * 0.0001 + noseSmoothZ * 0.9999;
                    // there is noise in the model sso we find the noise delta and remove it
                    deltaNoseX = landmarks[1].x - noseSmoothX;
                    deltaNoseXSmooth = deltaNoseX * 0.0001 + deltaNoseXSmooth * 0.9999;
                    deltaNoseX = deltaNoseXSmooth - deltaNoseX;
                    deltaNoseY = landmarks[1].y - noseSmoothY;
                    deltaNoseYSmooth = deltaNoseY * 0.0001 + deltaNoseYSmooth * 0.9999;
                    deltaNoseY = deltaNoseYSmooth - deltaNoseY;
                    deltaNoseZ = landmarks[1].z - noseSmoothZ;
                    deltaNoseZSmooth = deltaNoseZ * 0.0001 + deltaNoseZSmooth * 0.9999;
                    deltaNoseZ = deltaNoseZSmooth - deltaNoseZ;
                    //absolute value
                    if(deltaNoseX < 0){
                        deltaNoseX = deltaNoseX * -1;
                    }
                    if(deltaNoseY < 0){
                        deltaNoseY = deltaNoseY * -1;
                    }
                    if(deltaNoseZ < 0){
                        deltaNoseZ = deltaNoseZ * -1;
                    }
                    //deltaNose = (deltaNoseX + deltaNoseY + deltaNoseZ)/3;
                    deltaNose = Math.max(deltaNoseX, deltaNoseY, deltaNoseZ);
                    eyeDistance = 5*(landmarks[33].x - landmarks[263].x);
                    if(eyeDistance < 0){
                        eyeDistance = eyeDistance * -1;
                    }
                    deltaNoseNormalized = deltaNose / (eyeDistance);
                    //deltaSmoothing = deltaNoseNormalized * 0.0001 + deltaSmoothing * 0.9999;
                    stillness = (1-deltaNoseNormalized) * 100;
                    stillnessSmooth = stillness * 0.02 + stillnessSmooth * 0.98;
                }
                // remove decimals from stillness
                stillness = Math.round(stillness);
                setCurrentStillness(stillness);
                stillnessSmooth = Math.round(stillnessSmooth);
                setSmoothStillness(stillnessSmooth);
                // have two digits after decimal point
                ratioStillness = Math.round(stillness/stillnessSmooth * 100)/100;   
                setRatioSmoothStillness(ratioStillness);
                {/** 
                // sample every 1 out of 10 frames and get the value of standard deviation of ratioStillness
                if(Math.floor(Math.random() * 1000000) == 0)
                {
                    // calculate the mean of the ratioStillness and store it in ratioStillnessMean
                    ratioStillnessSum += ratioStillness; // Add to it
                    ratioStillnessCount = ratioStillnessCount + 1; // Add to it
                    //ratioStillnessMean = Math.round(ratioStillnessSum/ratioStillnessCount) || 0;
                    ratioStillnessMean = Number((ratioStillnessSum/ratioStillnessCount).toPrecision(4)) || 0;
                    // calculate the standard deviation of the ratioStillness and store it in ratioStillnessStdDev
                        // calculate the sum of the squared differences from the mean
                    ratioStillnessSumOfSquares += Math.pow(ratioStillness - ratioStillnessMean, 2);
                    // calculate the variance
                    ratioStillnessVariance = ratioStillnessSumOfSquares / ratioStillnessCount || 0;
                    // calculate the standard deviation of the ratioStillness and store it in ratioStillnessStdDev
                    ratioStillnessStdDev = Math.sqrt(ratioStillnessVariance) || 0;  
                    setRatioStillnessStdDeviation(ratioStillnessMean);
                    //ratioStillnessStdDev += Math.pow(ratioStillness - ratioStillnessMean, 2);
                    //ratioStillnessStdDev = Math.sqrt(ratioStillnessStdDev/ratioStillnessCount) || 0;
                    //setRatioStillnessStdDeviation(ratioStillnessMean);
                    //console.log("ratioStillnessStdDev: " + ratioStillnessStdDev);

                }
                */}
                // sample every 1 out of 10 frames and get the value of stillness
                if(Math.floor(Math.random() * 1000  ) == 0)
                {
                    // calculate the mean of the stillness and store it in stillnessMean
                    stillnessSum = stillnessSum + stillness;
                    stillnessCount++;
                    stillnessMean = Math.round(stillnessSum/stillnessCount);
                    setCurrentStillnessMean(stillnessMean);
                }
                {/** 
                // user reservoir samples to calculate the median of the stillness using 1000 samples
                // create a variable to store 1000 reservoir samples of stillness
                var reservoir = [];
                while(reservoir.length < reservoirLength)
                {
                    reservoir.push(stillness);
                }
                // if the reservoir is full, then we can replace a random value in the reservoir with the current stillness
                if(reservoir.length == reservoirLength)
                {
                    //var randomIndex = Math.floor(Math.random() * 1000);
                    //reservoir[randomIndex] = stillness;
                    // the probability that the new value will be added to the reservoir is 1000/n
                    var probability = reservoirLength/stillnessCount;
                    var randomValue = Math.random();
                    if(randomValue < probability)
                    {
                        var randomIndex = Math.floor(Math.random() * reservoirLength);
                        reservoir[randomIndex] = stillness;
                    }
                    // after every 10 samples, calculate the median of the reservoir
                    if(stillnessCount % 10 == 0)
                    {
                        // sort the reservoir
                        reservoir.sort(function(a, b){return a - b});
                        // if the reservoir has an even number of elements, then the median is the average of the two middle elements
                        if(reservoir.length % 2 == 0)
                        {
                            var middleIndex = reservoir.length/2;
                            var middleIndex2 = middleIndex - 1;
                            stillnessMedian = (reservoir[middleIndex] + reservoir[middleIndex2])/2;
                        }
                        // if the reservoir has an odd number of elements, then the median is the middle element
                        else
                        {
                            var middleIndex = Math.floor(reservoir.length/2);
                            stillnessMedian = reservoir[middleIndex];
                        }
                        setCurrentStillnessMedian(stillnessMedian);
                    }
                }
                */}
                // if ratioStillness is greater  than 1.2 or less than 0.8, then sudden movement detected
                if(ratioStillness > 1.38 || ratioStillness < 0.82) //1.35, 0.84 were the original values
                {
                    //generate_tts_11_labs("Stay Still. Be aware be equanimous.");
                    setSuddenMovementDetected(true);
                    // check if the function ran in the last ten minutes
                    var currentTime = new Date();
                    // extract the minute and hour from currentTime
                    var currentMinute = currentTime.getMinutes();
                    var currentHour = currentTime.getHours();
                    var currentTimeMinutes = currentHour*60 + currentMinute;
                    
                    // if currentTime does not exist in local storage then set it to the current time
                    if (localStorage.getItem("currentTimeMinutes") === null)
                    {
                        localStorage.setItem("currentTimeMinutes",currentTimeMinutes.toString());
                        generate_tts_11_labs("Stay Still. Be aware be equanimous.");
                        //console.log("*******************************currentTimeMinutes: " + currentTimeMinutes);
                    }
                    // else get the current time from local storage
                    // if the currentTime is 2 minutes or more greater than the current time in local storage, then we can run the function
                    else
                    {
                        // get currentTime from local storage
                        var storedTimeMinutes = localStorage.getItem("currentTimeMinutes");
                        // parse currentTime to int
                        var storedTimeMinutesInt = parseInt(storedTimeMinutes);

                        //console.log("*******************************currentTimeMinutes: " + currentTimeMinutes);
                        //console.log("*******************************storedTimeMinutes: " + storedTimeMinutes);
                    // if the current time is 10 minutes or more greater than the current time in local storage, then we can run the function
                        if(currentTimeMinutes > storedTimeMinutesInt+timeToWaitBeforeAlertingUser)
                        {
                            generate_tts_11_labs("Stay Still. Be aware be equanimous.");
                            // store currentTimeStringArrayStringArrayStringInt in local storage as currentTime
                            localStorage.setItem("currentTimeMinutes",currentTimeMinutes.toString());
                        }
                        // if the current time is less than the current time in local storage, then its from a previous dat -- lets reset it to now
                        if(currentTimeMinutes < storedTimeMinutesInt)
                        {
                            // store currentTimeStringArrayStringArrayStringInt in local storage as currentTime
                            localStorage.setItem("currentTimeMinutes",currentTimeMinutes.toString());
                        }
                    }
                }

                //console.log("ratioStillness: " + ratioStillness);
                //console.log("delta nose X :" + deltaNoseX);
                //console.log("delta nose Y :" + deltaNoseY);
                //console.log("delta nose Z :" + deltaNoseZ);
                //console.log("delta nose : " + deltaNose);
                //console.log("eye distance : " + eyeDistance);
                //console.log("delta nose normalized : " + deltaNoseNormalized);
                //console.log("delta nose Exp Smoothed : " + deltaSmoothing);
                //console.log("stillness : " + stillness);
                //console.log("stillnessS : " + stillnessSmooth);
                //console.log("left eye left most : " + landmarks[33].x + " " + landmarks[33].y + " " + landmarks[33].z); // left eye left most
                //console.log("right eye right : " + landmarks[263].x + " " + landmarks[263].y + " " + landmarks[263].z); // right eye right
            }
        }
        canvasCtx.restore();
    };

    const toggleWebCamVisibility = () => {
        setIsWebCamVisible(!isWebCamVisible);
        //console.log("toggleWebCamVisibility");
    };

    return (
        <Grid container className={classes.gridContainer} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper className={isDesktop?classes.paper:classes.paper_mobile} >
                    <Typography variant="button" display="block" gutterBottom>
                    Average Stillness: {currentStillnessMean} - Current Stillness: {currentStillness} - Minutes in Session: {minutesMeditatedInCurrentSession} - Total Hours Ever: {Math.floor(hoursMeditatedInTotal/60)}
                    </Typography>
                    {/**<Typography variant="button" display="block" gutterBottom> Current Stillness: {currentStillness} - Avg. Stillness: {smoothStillness} - Minutes in Session: {minutesMeditatedInCurrentSession} - Total Hours: {Math.floor(hoursMeditatedInTotal/60)} </Typography> */}
                    {/**<Typography variant="h5 " align="center" gutterBottom> Current Stillness: {currentStillness} - Avg. Stillness: {smoothStillness} - Minutes in Session: {minutesMeditatedInCurrentSession} - Total Hours: {Math.floor(hoursMeditatedInTotal/60)}</Typography> */}
                    {isDesktop && <Webcam audio={false} muted={true} ref={webcamRef} style={{position: "static", marginLeft: "auto", marginRight: "auto", marginTop:30, left: 0, right: 0, textAlign: "center", zindex: 1, width: 1216, height: 660}}/>}
                    {isDesktop && <canvas ref={canvasRef} style={{position: "absolute", marginLeft: "auto", marginRight: "auto",marginTop:30, left: 0, right: 0, textAlign: "center", zindex: 1, width: 1216, height: "82%"}}/>}
                    {isMobile && <Webcam audio={false} muted={true} ref={webcamRef} style={{position: "static", marginLeft: "auto", marginRight: "auto", marginTop:0, left: 0, right: 0, textAlign: "center", zindex: 1, width: "0%", height: "0%"}}/>}
                    {isMobile && <canvas ref={canvasRef} style={{position: "static", marginLeft: "auto", marginRight: "auto",marginTop:0,marginBottom:10, left: 0, right: 0, textAlign: "center", zindex: 1, width: "90%", height: "100%"}}/>}        
                    {isTablet && <Webcam audio={false} muted={true} ref={webcamRef} style={{position: "static", marginLeft: "auto", marginRight: "auto", marginTop:0, left: 0, right: 0, textAlign: "center", zindex: 1, width: "0%", height: "0%"}}/>}
                    {isTablet && <canvas ref={canvasRef} style={{position: "static", marginLeft: "auto", marginRight: "auto",marginTop:0, marginBottom:"3.2%", left: 0, right: 0, textAlign: "center", zindex: 1, width: "90%", height: "100%"}}/>}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default MPFaceMesh;

// map of face mesh
// https://github.com/google/mediapipe/blob/a908d668c730da128dfa8d9f6bd25d519d006692/mediapipe/modules/face_geometry/data/canonical_face_model_uv_visualization.png