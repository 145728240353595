import React from "react";
import styled from "styled-components";

const TvShowContainer = styled.div`
  width: 100%;
  min-height: 6em;
  display: flex;
  border-bottom: 2px solid #d8d8d852;
  padding: 6px 8px;
  align-items: center;
`;

const Thumbnail = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex: 0.4;

  img {
    width: 9em;//auto;
    height: 100%;
  }
`;

const Name = styled.h3`
  //border: none;
  //background: none;
  
  cursor: pointer;
  font-size: 18px;
  color: #000;
  margin-left: 1px;
  flex: 2;
  display: flex;

  &:hover {
    color: #000;//#708090;//#a9a9a9;
    font-size: 19.4px;
  }
`;

const Rating = styled.span`
  color: #a1a1a1;
  font-size: 16px;
  display: flex;
  flex: 0.2;
`;
const nameButtonClick = () => console.log('hi%%% *********************************');

export function TvShow(props) {
  const { thumbanilSrc, name, rating, callParent} = props;

  return (
    <TvShowContainer>
      <Thumbnail>
        <img src={thumbanilSrc} />
      </Thumbnail>
      <Name onClick={callParent}>{name.split(' ').slice(0, 54).join(' ')}</Name>      
    </TvShowContainer>
  );
}