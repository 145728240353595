import { makeStyles } from "@material-ui/core";
import { Grid } from  "@material-ui/core";
import { motion } from "framer-motion";
import {Card, CardActions, CardContent, CardMedia, Paper} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    itemClass: {
        height: "0",
        width: "0",
        background: "yellow",
    },
}));
  
function FramerWithMaterial() {
    const classes = useStyles();
    
    return (
        <main>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
{/**
                <Grid item
                xs = {3} 
                className={classes.itemClass} 
                component={motion.div}
                animate = {{rotate: [0,360]}}
                transition = {{repeat: Infinity, duration: 4}}
                style={{ scale: 1, originX: "100px", originY: "100px" }}
                >
                <Card className={classes.card}>
                <CardMedia className={classes.cardMedia} component="iframe" image={"./logo192.png"}/>
                </Card>
                </Grid>
*/}
                <Grid item                 
                    xs = {2.1}
                    className={classes.itemClass}
                    component={motion.div}
                    animate = {{rotate: [0,360]}}
                    transition = {{repeat: Infinity, duration: 10}}
                    style={{ scale: 0.3 }}
                    >
                    <Paper variant="outlined">
                        <img src={"./favicon.ico"}/>
                    </Paper>
                </Grid>
{/**
                <Grid item                 
                    xs = {2.1}
                    className={classes.itemClass}
                    component={motion.div}
                    animate = {{rotate: [0,360]}}
                    transition = {{repeat: Infinity, duration: 2}}
                    style={{ scale: 0.3, originX: "330px", originY: "330px" }}
                    >
                        <img src={"./favicon.ico"} />
                </Grid>
*/}
            <Grid item>
                <motion.img src={"./favicon.ico"} animate = {{rotate: [0,360]}} transition = {{repeat: Infinity, duration: 10}} style={{ scale: 0.3}} />
            </Grid>
                </Grid>
                <motion.img src={"./favicon.ico"} animate = {{rotate: [0,360]}} transition = {{repeat: Infinity, duration: 10}} style={{ scale: 0.3}} />
        </main>
    );
}

export default FramerWithMaterial;