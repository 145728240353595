import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
    container: {
        backgroundColor:theme.palette.background.paper,
        padding: theme.spacing(0,8,2),
    },
    icon: {
        marginRight:'12px',
    },
    cardGrid: {
        padding:'20px 80px',

    },
    card: {
        height: '100%',
        //width: '704px',
        display: 'flex',
        flexDirection: 'column',
        //flexGrow:1,
        padding: theme.spacing(0,0,0)
    },
    cardMedia: {
        height:'400px',
        //border: '10px solid firebrick',
        //width:'518px', using this makes the mobile look much better. need to figure out how to set different  dim for mobile and different for desktop
    },
    cardContent: {
        flexGrow:1,
    },
}));

export default useStyles;