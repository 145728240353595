// log in local storage the last time the user has been active
// check if the user was active yesterday, if yes then increase the count of the days he has been active
// if not, then reset the count of the days he has been active

function returnStatistics(inputText)
{
  // check if the user was active yesterday, if yes then increase the count of the days he has been active
  // if not, then reset the count of the days he has been active
  // get the value from local storage for the last day the user has been active
  // if the value is not set, then set it to today
    const last_day_active = localStorage.getItem("last_day_active");
    var number_of_days_consisent = localStorage.getItem("number_of_days_consisent");
    const today = new Date().toISOString().slice(0, 10);
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
    
    if(localStorage.getItem("number_of_days_consisent") === null)
    {
        localStorage.setItem("number_of_days_consisent", 0);
        number_of_days_consisent = 0;
    }
    if (last_day_active === null)
    {
        localStorage.setItem("last_day_active", new Date().toISOString().slice(0, 10));
        localStorage.setItem("number_of_days_consisent", 0);
    }
    else
    {
        // check if the last day the user has been active is yesterday
        // if yes, then increase the number of days the user has been active
        // if not, then reset the number of days the user has been active
        console.log("today: " + today);
        console.log("yesterday: " + yesterday);
        console.log("last_day_active: " + last_day_active);
        if (last_day_active === yesterday)
        {
            // increase the number of days the user has been active
            number_of_days_consisent = localStorage.getItem("number_of_days_consisent");
            // convert the string to a number
            number_of_days_consisent = parseInt(number_of_days_consisent);
            number_of_days_consisent = number_of_days_consisent + 1;
            localStorage.setItem("number_of_days_consisent", number_of_days_consisent);
            // set the last day the user has been active to today
            localStorage.setItem("last_day_active", today);
        }
        else if(last_day_active === today)
        {
            // do nothing
        }
        else
        {
            // set the last day the user has been active to today
            localStorage.setItem("last_day_active", today);
            // reset the number of days the user has been active
            localStorage.setItem("number_of_days_consisent", 0);
        }
    }
    if(number_of_days_consisent === 1 || number_of_days_consisent === "1")
    {
        return "You have been consistent for " + number_of_days_consisent + " day";
    }
    return "You have been consistent for " + number_of_days_consisent + " days";
};

// Export the textToSpeech function as the default export of this module.
export default returnStatistics;
