import React, { useEffect } from "react";
import { useState } from "react";
import {Typography, AppBar, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Toolbar, Container} from '@material-ui/core';
import {PhotoCamera} from '@material-ui/icons';
//import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import useStyles from "./styles";
//import styled from "styled-components";
import { SearchBar } from "./components/searchBar";
import { AnswerVideos } from "./components/answerVideos";
import { VoiceInterface } from "./components/voiceInterface";
import  MPFaceMesh  from "./components/mpFaceMesh";
//import { IoClose } from "react-icons/io5";
//import { WelcomeMessage } from "./components/welcomeMessage";
//const cards = [1,2,3,4]
//const videos = ["https://www.youtube.com/embed/UtDtA8EX-fw","https://www.youtube.com/embed/5tB9C9Ae1_g","https://www.youtube.com/embed/Oh5ii6R6LTM","https://www.youtube.com/embed/U3S0ui8pIIU","https://www.youtube.com/embed/Sc0YNsY_Xdc","https://www.youtube.com/embed/Te7U6ZkwIkI","https://www.youtube.com/embed/ZLLnxq_MbJM","https://www.youtube.com/embed/J2VcZWypI9c?start=1033&end=200"]
import ReactGA from "react-ga4";

const trackingId = "G-99K83RJPM5"; // Google Analytics tracking ID
ReactGA.initialize(trackingId);

const videos_array = [
    {
        video:"https://www.youtube.com/embed/Oh5ii6R6LTM",
        teacher: "S. N. Goenka",
        subject: "20 Minutes Anna Panna (Breath Observation) guided meditation"
    },
    {
        video:"https://www.youtube.com/embed/Sc0YNsY_Xdc",
        teacher: "Ayya Khema",
        subject: "15 minutes Loving Kindness guided meditation"
    },
    {
        video:"https://www.youtube.com/embed/U3S0ui8pIIU",
        teacher: "S. N. Goenka",
        subject: "20 minutes Loving Kindness guided meditation"
    },
    {
        video:"https://www.youtube.com/embed/3FCDGlPflfY",
        teacher: "Ayya Khema",
        subject: "How to Meditate?"
    },
//    {
//        video:"https://www.youtube.com/embed/wbam0RPaoLI",
//        teacher: "Ayya Santussika",
//        subject: "30 minutes Anna Panna (Breath Observation) guided meditation"
//    },
    {
        video:"https://www.youtube.com/embed/Vk1ZG5MmJjc",
        teacher: "S. N. Goenka",
        subject: "1 hour Vipassana guided meditation"
    },
    {
        video:"https://www.youtube.com/embed/UtDtA8EX-fw",
        teacher: "Ayya Khema",
        subject: "1 hour Body Scan guided meditation"
    },
    {
        video:"https://www.youtube.com/embed/ZLLnxq_MbJM",
        teacher: "Bhikku Bodhi",
        subject: "What is Meditation?"
    },
    {
        video:"https://www.youtube.com/embed/HEW6jEjVXVk",
        teacher: "Jetsunma Tenzin Palmo",
        subject: "Understanding Meditation"
    },
]
const App = () => 
{
    const classes = useStyles();
    const [videoList, setVideoList] = useState(videos_array);
    const setVideoListFunc = (videos_array)=>
    {
      setVideoList(videos_array);
    };
    const [showFaceMesh, setShowFaceMesh] = useState(false);
    var user_agent = navigator.userAgent.toLowerCase();
    var isMobile = false;
    var isTablet = false;
    var isDesktop = true;
    // if the users agent has the word iphone or android in it, then it is a mobile device
    if(user_agent.indexOf("iphone") != -1 || user_agent.indexOf("android") != -1){isMobile = true;}
    // if the users agent has the word ipad or tablet in it, then it is a tablet device
    if(user_agent.indexOf("ipad") != -1 || user_agent.indexOf("tablet") != -1){isTablet = true;}
    if(isMobile || isTablet){isDesktop = false;}

    return (
        <>
         <CssBaseline/>
         <main>
         <div>
         <Container maxWidth="xl" style={{marginTop:'80px'}}>
            <VoiceInterface/>
         </Container>
         </div>
         <div>
         <Container maxWidth="xl" style={{marginTop:'80px'}}>
             {showFaceMesh && (<MPFaceMesh/>)}
         </Container>
         </div>
         {/*
         {isDesktop && <div className={classes.container} style={{marginBottom:'48px',marginTop: "0px"} }>
            <SearchBar setVideoListFunc={setVideoListFunc}/>
         </div>}
         */}
         {isDesktop && <div style={{ marginTop: '80px' }}>
         <AnswerVideos videoList={videoList}/>
         </div>}
         {isDesktop &&  <Container className={classes.cardGrid} maxWidth="xl" align="center">
                 <Typography gutterTop gutterBottom variant="h5" style={{paddingTop: "64px"}}>
                     May All Beings Be Happy, May All Beings Be Peaceful
                </Typography>
                <Typography gutterBottom variant="h5" style={{marginBottom:'44px',marginTop: "0px"}}>
                     ~S. N. Goenka
                </Typography>
             </Container> }
         </main>
        </>
    )
}

export default App;

/*
This is the code for the matrix of videos, the code is moved out to create its own component

                 <Grid container spacing={10} justify="center">
                     {videos_array.map((video)=>(
                     <Grid item xs={12} sm={6} style={{paddingTop: "40px"}}>
                         <Card className={classes.card}>
                             <CardMedia className={classes.cardMedia} component="iframe" image={video.video}/>
                             <CardContent className={classes.cardContent}>
                                 <Typography gutterBottom variant="h5">
                                     {video.teacher}
                                 </Typography>
                                 <Typography>
                                 {video.subject}
                                 </Typography>
                             </CardContent>
                         </Card>
                     </Grid>
                     ))}
                 </Grid>


*/